import React from 'react';

const Twitter = (props) => (
	<svg
		viewBox="0 0 200 200"
		css={{
			width: '100%',
			height: 'auto',
			margin: 'auto',
			display: 'block'
		}}
		{...props}>
		<title>Follow @tomastech_ on Twitter</title>
		<path
			d="M199 39.29a90.81 90.81 0 0 1-23 6.56A42.56 42.56 0 0 0 193.53 24a100.34 100.34 0 0 1-25.16 9.85c-7.66-7.66-17.5-13.13-29.54-13.13-21.88 0-40.48 18.6-40.48 40.48 0 3.28 0 6.56 1.09 8.75a114.36 114.36 0 0 1-84.22-41.6A37.78 37.78 0 0 0 9.75 48c0 14.22 7.66 26.25 17.5 33.91a36.88 36.88 0 0 1-18.6-5.47 40 40 0 0 0 32.82 39.38C38.19 117 34.91 117 30.54 117c-2.19 0-5.47 0-7.66-1.09 5.47 16.41 19.69 27.35 38.29 28.44a82.59 82.59 0 0 1-50.32 17.5c-3.28 0-6.56 0-9.85-1.09 17.5 10.94 39.38 18.6 62.35 18.6 74.39 0 114.86-61.26 114.86-114.86V59C187 54.6 193.53 48 199 39.29z"
			fill="currentColor"
		/>
	</svg>
);

export default Twitter;
