import React from 'react';
import { Vars } from 'utils/vars';

const pStyles = {
	marginTop: 0,
	marginBottom: `calc(${Vars.gutter} * 1.5)`,
	lineHeight: 1.4,
	':last-child': {
		marginBottom: 0
	},
	'@media (min-width: 576px)': {
		lineHeight: Vars.lineHeightBody
	}
};

const P = ({ children, ...other }) => (
	<p css={pStyles} {...other}>
		{children}
	</p>
);

export default P;
