import React from 'react';
import { Vars } from 'utils/vars';
import { Grid } from 'utils/grid';

import P from 'components/P';
import Social from 'components/Social';

const footerStyles = [
	Grid,
	{
		width: '100%',
		overflow: 'hidden',
		padding: `${Vars.gap} calc(${Vars.gutter} / 2)`,
		alignItems: 'center',
		justifyContent: 'center',
		lineHeight: 1.3
	}
];

const copyBlockStyles = {
	'@media (min-width: 768px)': {
		flexGrow: 1
	}
};

const copyrightStyles = {
	fontSize: '.875rem',
	letterSpacing: '1px',
	fontFamily: Vars.fontFamilyHead,
	textTransform: 'uppercase',
	textAlign: 'center',
	padding: `${Vars.gutter} 0`,
	'@media (min-width: 768px)': {
		padding: `0 ${Vars.gutterHalf}`,
		textAlign: 'left'
	}
};

const socialStyles = {
	'@media (min-width: 768px)': {
		flexShrink: 0
	}
};

const imageBlockStyles = {
	'@media (min-width: 768px)': {
		flexShrink: 0
	}
};

const imageStyles = {
	width: 'auto',
	margin: `0 ${Vars.gutterHalf}`,
	height: Vars.gutter
};

const Footer = () => (
	<footer css={footerStyles}>
		<div css={imageBlockStyles}>
			<img css={imageStyles} src="/media/tomas-tech-mark.svg" alt="Tomas.Tech Brand Mark" />
		</div>
		<div css={copyBlockStyles}>
			<P css={copyrightStyles}>&copy; {new Date().getFullYear()} Tomas Jurkevičius. All rights reserved.</P>
		</div>
		<div css={socialStyles}>
			<Social />
		</div>
	</footer>
);

export default Footer;
