import React from 'react';
import { Vars } from 'utils/vars';

const h1Styles = {
	marginTop: 0,
	marginBottom: `calc(${Vars.gutter} / 2)`,
	fontSize: '32px',
	fontFamily: Vars.fontFamilyHead,
	fontWeight: Vars.fontWeightHead,
	lineHeight: Vars.lineHeightBase,
	':last-child': {
		marginBottom: 0
	},
	'@media (min-width: 576px)': {
		fontSize: '44px'
	},
	'@media (min-width: 768px)': {
		fontSize: '48px'
	},
	'@media (min-width: 992px)': {
		fontSize: '50px'
	}
};

const H1 = ({ children }) => <h1 css={h1Styles}>{children}</h1>;

export default H1;
