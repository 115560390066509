import React from 'react';

const Github = (props) => (
	<svg
		viewBox="0 0 200 200"
		css={{
			width: '100%',
			height: 'auto',
			margin: 'auto',
			display: 'block'
		}}
		{...props}>
		<title>See Tomas’ hacks on GitHub</title>
		<path
			d="M100 3.46a99 99 0 0 0-31.3 192.91c4.95.93 6.76-2.13 6.76-4.76 0-2.35-.09-8.58-.12-16.83C47.8 180.75 42 161.5 42 161.5 37.48 150.07 31 147 31 147c-9-6.14.69-6 .69-6 9.94.69 15.16 10.2 15.16 10.2 8.79 15.14 23.15 10.8 28.8 8.23.89-6.4 3.44-10.77 6.27-13.24-22-2.47-45.08-11-45.08-48.91A38.22 38.22 0 0 1 47 70.72c-1.11-2.5-4.45-12.56.87-26.2 0 0 8.29-2.66 27.22 10.14a93.25 93.25 0 0 1 49.49 0c18.81-12.8 27.1-10.14 27.1-10.14 5.32 13.64 2 23.7 1 26.2a38.33 38.33 0 0 1 10.15 26.56c0 38-23.14 46.4-45.16 48.83 3.46 3 6.69 9 6.69 18.31 0 13.25-.12 23.89-.12 27.11 0 2.6 1.74 5.69 6.81 4.7A98.87 98.87 0 0 0 100 3.46z"
			fill="currentColor"
		/>
	</svg>
);

export default Github;
