import React from 'react';
import { css } from 'glamor';
import { Link } from 'gatsby';
import { Vars } from 'utils/vars';

import Logo from 'components/Logo';

const brandStyles = {
	flex: '1 0 auto',
	lineHeight: 0
};

const brandLinkStyles = css({
	border: 0,
	display: 'inline-block',
	textDecoration: 'none',
	color: Vars.colorMetal,
	transition: 'color .25s ease',
	':hover,:focus': {
		color: Vars.colorGreen
	}
});

const brandLinkActiveStyles = css({
	cursor: 'default',
	pointerEvents: 'none'
});

const brandImageStyles = {
	width: '115px',
	height: Vars.gutter,
	margin: 0
};

const Brand = () => (
	<div css={brandStyles}>
		<Link
			to="/"
			exact="true"
			className={`${brandLinkStyles}`}
			activeClassName={`${brandLinkActiveStyles}`}
			title="Return to the homepage">
			<Logo css={brandImageStyles} />
		</Link>
	</div>
);

export default Brand;
