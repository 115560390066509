export let Vars = {
	colorPink: '#fd476c',
	colorBlue: '#391d71',
	colorDark: '#20133a',
	colorGreen: '#4eeca5',
	colorMetal: '#dfe5eb',
	colorWhite: '#ffffff',
	colorGrape: '#341448',
	colorGrapeLight: '#3c1544',
	fontFamilyBase: 'sans-serif',
	fontFamilyBody: '"Roboto", sans-serif',
	fontFamilyHead: '"Roboto Condensed", sans-serif',
	fontWeightBody: 400,
	fontWeightHead: 700,
	fontSizeBase: '16px',
	fontSizeBody: '1.125rem',
	lineHeightBase: 1,
	lineHeightBody: 1.6,
	lineHeightHead: 1.4,
	gutter: '30px',
	gutterHalf: '15px',
	gap: '60px',
	gapDouble: '120px'
};
