import React from 'react';
import { Grid } from 'utils/grid';
import { Vars } from 'utils/vars';

import Brand from 'components/Brand';

const headerStyles = {
	position: 'absolute',
	overflow: 'hidden',
	width: '100%',
	zIndex: 10,
	top: 0,
	left: 0
};

const containerStyles = [
	Grid,
	{
		alignItems: 'center',
		padding: `${Vars.gutter}`
	}
];

const Header = () => (
	<header css={headerStyles}>
		<div css={containerStyles}>
			<Brand />
		</div>
	</header>
);

export default Header;
