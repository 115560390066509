import React from 'react';
import anime from 'animejs';
import { Vars } from 'utils/vars';

const styles = {
	position: 'absolute',
	zIndex: 1,
	top: '50%',
	left: '50%',
	width: '100%',
	minWidth: '800px',
	height: 'auto',
	transform: 'translate(-50%, -50%)',
	pointerEvents: 'none',
	userSelect: 'none'
};

class HeroSceneIllustration extends React.Component {
	constructor(props) {
		super(props);

		this.heroBlob1 = React.createRef();
		this.heroBlob2 = React.createRef();

		this.animation = [];
	}

	componentDidMount() {
		this.animateBlob(this.heroBlob1.current, 18000, 'linear', 0);
		this.animateBlob(this.heroBlob2.current, 9000, 'easeOutQuad', 600);
	}

	componentWillUnmount() {
		this.animation.forEach((anim) => {
			anim.pause();
		});
		this.animation = [];
	}

	animateBlob(node, duration, elasticity) {
		let path = node.getAttribute('data-next').split(';');
		path.splice(path.length, 0, node.getAttribute('d'));

		this.animation.push(
			anime({
				targets: node,
				duration: duration,
				easing: 'linear',
				elasticity: elasticity,
				d: path,
				loop: true
			})
		);
	}

	render() {
		return (
			<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1244 700" css={styles}>
				<g id="hero-pattern">
					<path
						d="M263.55,106.49a1.29,1.29,0,0,1,1.29-1.29h3.28v-3.28a1.29,1.29,0,1,1,2.58,0v3.28H274a1.29,1.29,0,1,1,0,2.58h-3.28v3.28a1.29,1.29,0,1,1-2.58,0v-3.28h-3.28A1.29,1.29,0,0,1,263.55,106.49Z"
						fill={Vars.colorBlue}
					/>

					<path
						d="M1046.61,664.2a1.29,1.29,0,0,0-1.29,1.29v3.28H1042a1.29,1.29,0,0,0,0,2.58h3.28v3.28a1.29,1.29,0,0,0,2.58,0v-3.28h3.28a1.29,1.29,0,0,0,0-2.58h-3.28v-3.28A1.29,1.29,0,0,0,1046.61,664.2Z"
						fill={Vars.colorBlue}
					/>

					<path
						d="M362,510.32h3.28v3.28a1.29,1.29,0,0,0,2.58,0v-3.28h3.28a1.29,1.29,0,0,0,0-2.58h-3.28v-3.28a1.29,1.29,0,0,0-2.58,0v3.28H362A1.29,1.29,0,0,0,362,510.32Z"
						fill={Vars.colorBlue}
					/>

					<path
						d="M621.06,309.05h3.28v3.28a1.29,1.29,0,1,0,2.58,0v-3.28h3.28a1.29,1.29,0,0,0,0-2.58h-3.28V303.2a1.29,1.29,0,1,0-2.58,0v3.28h-3.28A1.29,1.29,0,1,0,621.06,309.05Z"
						fill={Vars.colorBlue}
					/>

					<path
						d="M912.51,208.41h3.28v3.28a1.29,1.29,0,0,0,2.58,0v-3.28h3.28a1.29,1.29,0,1,0,0-2.58h-3.28v-3.28a1.29,1.29,0,1,0-2.58,0v3.28h-3.28A1.29,1.29,0,1,0,912.51,208.41Z"
						fill={Vars.colorPink}
					/>

					<path
						d="M395.37,191.52a1.29,1.29,0,0,0,1.82,0l2.7-2.7,2.7,2.7a1.29,1.29,0,1,0,1.82-1.82l-2.7-2.7,2.7-2.7a1.29,1.29,0,0,0-1.82-1.82l-2.7,2.7-2.7-2.7a1.29,1.29,0,0,0-1.82,1.82l2.7,2.7-2.7,2.7a1.29,1.29,0,0,0,0,1.82h0Z"
						fill={Vars.colorPink}
					/>

					<path
						d="M825.4,585a1.29,1.29,0,0,0-1.82,0l-2.7,2.7-2.7-2.7a1.29,1.29,0,1,0-1.82,1.82l2.7,2.7-2.7,2.7a1.29,1.29,0,1,0,1.82,1.82l2.7-2.7,2.7,2.7a1.29,1.29,0,1,0,1.82-1.82l-2.7-2.7,2.7-2.7a1.29,1.29,0,0,0,0-1.82h0Z"
						fill={Vars.colorPink}
					/>

					<path
						d="M1177.1,426.71l-2.7-2.7a1.29,1.29,0,1,0-1.82,1.82l2.7,2.7-2.7,2.7a1.29,1.29,0,1,0,1.82,1.82l2.7-2.7,2.7,2.7a1.29,1.29,0,0,0,1.82-1.82l-2.7-2.7,2.7-2.7a1.29,1.29,0,0,0-1.82-1.82Z"
						fill={Vars.colorBlue}
					/>

					<path
						d="M686.83,473.3a1.29,1.29,0,0,0,1.82,0l2.7-2.7,2.7,2.7a1.29,1.29,0,0,0,1.82-1.82l-2.7-2.7,2.7-2.7a1.29,1.29,0,1,0-1.82-1.82l-2.7,2.7-2.7-2.7a1.29,1.29,0,1,0-1.82,1.82l2.7,2.7-2.7,2.7a1.29,1.29,0,0,0,0,1.82h0Z"
						fill={Vars.colorGreen}
					/>

					<path
						d="M749.3,9.85a5.85,5.85,0,1,1,5.85,5.85,5.86,5.86,0,0,1-5.85-5.85h0Z"
						fill={Vars.colorBlue}
					/>

					<circle cx="790.12" cy="25.98" r="3.28" fill={Vars.colorBlue} />

					<path
						d="M846.45,388.27a5.85,5.85,0,1,1,5.85,5.85,5.86,5.86,0,0,1-5.85-5.85h0Z"
						fill={Vars.colorBlue}
					/>

					<circle cx="887.27" cy="348.02" r="3.28" fill={Vars.colorGreen} />

					<path
						d="M172.25,374a5.85,5.85,0,1,1,5.85-5.85,5.86,5.86,0,0,1-5.85,5.85h0Z"
						fill={Vars.colorBlue}
					/>

					<circle cx="239.6" cy="327.89" r="3.28" fill={Vars.colorBlue} />

					<circle cx="1078.99" cy="287.64" r="5.85" fill={Vars.colorGreen} />

					<circle cx="1146.34" cy="267.51" r="3.28" fill={Vars.colorPink} />

					<circle cx="301.79" cy="690.18" r="5.85" fill={Vars.colorBlue} />

					<circle cx="336.75" cy="649.93" r="3.28" fill={Vars.colorGreen} />

					<path
						d="M424.94,87.27a1.29,1.29,0,0,1,0-1.82l5-5a1.33,1.33,0,0,1,1.82,0l5,5a1.29,1.29,0,0,1,0,1.82l-5,5a1.29,1.29,0,0,1-1.82,0Z"
						fill={Vars.colorBlue}
					/>

					<rect
						x="464.1"
						y="43.85"
						width="4.52"
						height="4.52"
						transform="translate(103.99 343.27) rotate(-45)"
						fill={Vars.colorPink}
					/>

					<path
						d="M133.49,568.51l5-5a1.29,1.29,0,0,1,1.82,0l5,5a1.29,1.29,0,0,1,0,1.82l-5,5a1.29,1.29,0,0,1-1.82,0l-5-5a1.29,1.29,0,0,1,0-1.82h0Z"
						fill={Vars.colorBlue}
					/>

					<rect
						x="205.03"
						y="547.03"
						width="4.52"
						height="4.52"
						transform="translate(-327.69 307.46) rotate(-45)"
						fill={Vars.colorPink}
					/>

					<path
						d="M651.63,167.78a1.29,1.29,0,0,1,0-1.82l5-5a1.33,1.33,0,0,1,1.82,0l5,5a1.29,1.29,0,0,1,0,1.82l-5,5a1.29,1.29,0,0,1-1.82,0Z"
						fill={Vars.colorBlue}
					/>

					<rect
						x="723.17"
						y="144.48"
						width="4.52"
						height="4.52"
						transform="translate(108.71 555.94) rotate(-45)"
						fill={Vars.colorGreen}
					/>

					<path
						d="M1007.84,127.53a1.29,1.29,0,0,1,0-1.82l5-5a1.29,1.29,0,0,1,1.82,0l5,5a1.29,1.29,0,0,1,0,1.82l-5,5a1.29,1.29,0,0,1-1.82,0Z"
						fill={Vars.colorBlue}
					/>

					<rect
						x="1111.77"
						y="63.97"
						width="4.52"
						height="4.52"
						transform="translate(279.46 807.14) rotate(-45)"
						fill={Vars.colorBlue}
					/>

					<path
						d="M954.94,528.25a1.29,1.29,0,0,1,0,1.82l-5,5a1.29,1.29,0,0,1-1.82,0l-5-5a1.29,1.29,0,0,1,0-1.82l5-5a1.29,1.29,0,0,1,1.82,0Z"
						fill={Vars.colorGreen}
					/>

					<rect
						x="982.24"
						y="486.65"
						width="4.52"
						height="4.52"
						transform="translate(-57.36 839.34) rotate(-45)"
						fill={Vars.colorBlue}
					/>

					<path
						d="M496.55,442.72l5,5a1.29,1.29,0,0,1,0,1.82l-5,5a1.29,1.29,0,0,1-1.82,0l-5-5a1.29,1.29,0,0,1,0-1.82l5-5a1.29,1.29,0,0,1,1.82,0h0Z"
						fill={Vars.colorBlue}
					/>

					<rect
						x="528.87"
						y="406.14"
						width="4.52"
						height="4.52"
						transform="translate(-133.22 495.18) rotate(-45)"
						fill={Vars.colorBlue}
					/>

					<path
						d="M68.72,248.28a1.29,1.29,0,0,1,0-1.82l5-5a1.33,1.33,0,0,1,1.82,0l5,5a1.29,1.29,0,0,1,0,1.82l-5,5a1.29,1.29,0,0,1-1.82,0Z"
						fill={Vars.colorBlue}
					/>

					<rect
						x="107.88"
						y="224.99"
						width="4.52"
						height="4.52"
						transform="translate(-128.43 144.44) rotate(-45)"
						fill={Vars.colorBlue}
					/>

					<path
						d="M566.33,628.88a1.29,1.29,0,0,1,0,1.82l-5,5a1.29,1.29,0,0,1-1.82,0l-5-5a1.29,1.29,0,0,1,0-1.82l5-5a1.33,1.33,0,0,1,1.82,0Z"
						fill={Vars.colorBlue}
					/>

					<rect
						x="593.63"
						y="607.41"
						width="4.52"
						height="4.52"
						transform="translate(-256.57 599.93) rotate(-45)"
						fill={Vars.colorGreen}
					/>
				</g>

				<path
					ref={this.heroBlob1}
					css={{ transformOrigin: '50% 50%' }}
					d="M1383,224c-71.71-105-212.2-189.36-323-159-58.14,15.93-50.67,47.7-143,87-98.95,42.12-141.51,20.08-193,65-35.48,31-71.17,90.17-64,150,13.52,112.75,166.12,118.06,205,251,26.68,91.24,228.47,213.92,373,105,120.55-90.85,62-213.32,65-249.32C1310,390.15,1460,336.79,1383,224Z"
					data-next="M1139.16,28.65c-125.44-21-283.55,22-338.36,123-28.76,53-.66,69.59-36.23,163.42-38.12,100.56-83.48,116-86.72,184.27-2.23,47,15.79,113.79,63.93,150,90.71,68.31,200.2-38.13,323,25.86,84.31,43.92,312.54-16.75,334-196.45,17.91-149.88-111-192.43-134.9-219.52C1208.49,196.39,1273.86,51.18,1139.16,28.65Z;M843.73,61.26c-105,71.71-189.36,212.2-159,323,15.93,58.14,47.7,50.67,87,143,42.12,98.95,20.08,141.51,65,193,31,35.48,90.17,71.17,150,64,112.75-13.52,118.06-166.12,251-205,91.24-26.68,213.92-228.47,105-373-90.85-120.55-213.32-62-249.32-65C1009.89,134.3,956.53-15.74,843.73,61.26Z;M1139.16,28.65c-125.44-21-283.55,22-338.36,123-28.76,53-.66,69.59-36.23,163.42-38.12,100.56-83.48,116-86.72,184.27-2.23,47,15.79,113.79,63.93,150,90.71,68.31,200.2-38.13,323,25.86,84.31,43.92,312.54-16.75,334-196.45,17.91-149.88-111-192.43-134.9-219.52C1208.49,196.39,1273.86,51.18,1139.16,28.65Z"
					fill={Vars.colorBlue}
					opacity="0.5"
				/>

				<path
					ref={this.heroBlob2}
					css={{ transformOrigin: '50% 50%' }}
					d="M1373,164c-64.3-83.15-221.52-72.51-297-16-15.36,11.5-18.68,18.42-34,24-66.3,24.15-119.75-63.24-182-48-43.42,10.63-81.06,68.72-75,115,10.51,80.34,146,73.9,171,155,33.26,107.86-177.6,212.56-144,336,15.87,58.31,77.06,86.93,105,100,143.77,67.25,349.34-17.22,384-130,28.19-91.73-72.59-150.38-34-244,30.6-74.24,108.29-72.09,131-155C1410.39,255.76,1402,201.46,1373,164Z"
					data-next="M1373,164c-64.3-83.15-223.52-145.51-299-89-15.36,11.5-18.68,18.42-34,24-66.3,24.15-127.75,5.76-190,21-43.42,10.63-71.06,72.72-65,119,10.51,80.34,33,112.9,58,194,33.26,107.86-12.6,135.56,21,259,15.87,58.31,77.06,86.93,105,100,143.77,67.25,297.34,20.78,332-92,28.19-91.73-72.59-150.38-34-244,30.6-74.24,108.29-72.09,131-155C1410.39,255.76,1402,201.46,1373,164Z;M1292,173c-64.3-83.15-142.52-154.51-218-98-15.36,11.5-18.68,18.42-34,24-66.3,24.15-182.75-26.24-245-11-43.42,10.63-71.06,72.72-65,119,10.51,80.34-68,169.9-43,251,33.26,107.86,143.4,110.56,177,234,15.87,58.31,77.06,86.93,105,100,143.77,67.25,297.34,20.78,332-92,28.19-91.73-72.59-150.38-34-244,30.6-74.24,108.29-72.09,131-155C1410.39,255.76,1321,210.46,1292,173Z"
					fill={Vars.colorBlue}
					opacity="0.5"
				/>
			</svg>
		);
	}
}

export default HeroSceneIllustration;
