import React from 'react';
import { css } from 'glamor';
import { Link } from 'gatsby';
import { Vars } from 'utils/vars';

const buttonStyle = {
	display: 'inline-block',
	padding: `calc(${Vars.gutter} / 3) ${Vars.gutter}`,
	color: Vars.colorDark,
	backgroundColor: Vars.colorGreen,
	border: 0,
	borderRadius: `calc(${Vars.gutter} / 3)`,
	fontSize: Vars.fontSizeBase,
	fontFamily: Vars.fontFamilyHead,
	fontWeight: Vars.fontWeightHead,
	textDecoration: 'none',
	textTransform: 'uppercase',
	letterSpacing: '.5px',
	transition: 'color .25s ease, background-color .25s ease',
	':hover,:focus': {
		color: Vars.colorMetal,
		backgroundColor: Vars.colorBlue
	}
};

const linkStyle = {
	textDecoration: 'none',
	color: Vars.colorDark,
	fontWeight: Vars.fontWeightHead,
	borderBottom: `2px solid ${Vars.colorGreen}`,
	transition: 'border-bottom-color .25s ease',
	':hover,:focus': {
		borderBottomColor: Vars.colorBlue
	}
};

const linkStyleLight = {
	textDecoration: 'none',
	color: Vars.colorMetal,
	fontWeight: Vars.fontWeightHead,
	borderBottom: `2px solid ${Vars.colorGreen}`,
	transition: 'border-bottom-color .25s ease',
	':hover,:focus': {
		borderBottomColor: Vars.colorMetal
	}
};

const blankStyle = {
	cursor: 'pointer'
};

const getStyles = (isBlank, isLink, isLight) => {
	return isBlank ? blankStyle : isLink ? (isLight ? linkStyleLight : linkStyle) : buttonStyle;
};

const Button = ({ children, to, isInternal, isLink, isLight, isBlank, ...other }) => {
	let handleOutboundLinkClicks = (event) => {
		event.currentTarget.blur();

		event.preventDefault();

		let href = event.currentTarget.href;

		if (window.ga) {
			window.ga('send', 'event', {
				eventCategory: 'Outbound Link',
				eventAction: 'click',
				eventLabel: href,
				transport: 'beacon'
			});
		}

		window.open(href, '_blank');
	};

	if (isInternal) {
		return (
			<Link to={to} className={css(getStyles(isBlank, isLink, isLight))} {...other}>
				{children}
			</Link>
		);
	}

	return (
		<a href={to} css={getStyles(isBlank, isLink, isLight)} onClick={handleOutboundLinkClicks} {...other}>
			{children}
		</a>
	);
};

Button.defaultProps = {
	isLink: false,
	isLight: false,
	isBlank: false,
	isInternal: false
};

export default Button;
