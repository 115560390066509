import React from 'react';
import { Vars } from 'utils/vars';

const h2Styles = {
	marginTop: 0,
	marginBottom: Vars.gutter,
	fontSize: '20px',
	fontFamily: Vars.fontFamilyHead,
	fontWeight: Vars.fontWeightBody,
	lineHeight: Vars.lineHeightHead,
	':last-child': {
		marginBottom: 0
	},
	'@media (min-width: 992px)': {
		fontSize: '24px'
	}
};

const H2 = ({ children }) => <h2 css={h2Styles}>{children}</h2>;

export default H2;
