import React from 'react';

const Linkedin = (props) => (
	<svg
		viewBox="0 0 200 200"
		css={{
			width: '100%',
			height: 'auto',
			margin: 'auto',
			display: 'block'
		}}
		{...props}>
		<title>Connect with Tomas on LinkedIn</title>
		<path
			d="M169.75 169.3H140v-46.2c0-11 0-25.3-15.4-25.3S107 109.9 107 122v46.2H78.45V74.7H107v13.2c4.4-7.7 13.2-15.4 27.5-15.4 29.7 0 35.2 19.8 35.2 45.1v51.7zM45.45 62.6c-9.9 0-16.5-7.7-16.5-17.6s6.6-16.5 16.5-16.5A16.2 16.2 0 0 1 61.95 45c0 8.8-6.6 17.6-16.5 17.6zm14.3 106.7h-28.6V75.8h29.7v93.5h-1.1zM185.15 1H15.75a14.56 14.56 0 0 0-14.3 14.3v169.4a14.56 14.56 0 0 0 14.3 14.3H184a14.56 14.56 0 0 0 14.3-14.3V15.3C199.4 7.6 192.8 1 185.1 1z"
			fill="currentColor"
		/>
	</svg>
);

export default Linkedin;
