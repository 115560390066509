import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { css } from 'glamor';
import { Vars } from 'utils/vars';

import Main from 'components/Main';
import Header from 'components/Header';
import Footer from 'components/Footer';

css.global('::selection', {
	background: Vars.colorMetal
});

css.global('*,*:before,*:after', {
	boxSizing: 'inherit'
});

css.global('html', {
	boxSizing: 'border-box',
	fontSize: Vars.fontSizeBase,
	lineHeight: Vars.lineHeightBase,
	background: Vars.colorGrape,
	textSizeAdjust: '100%'
});

css.global('body', {
	margin: 0,
	color: Vars.colorDark,
	background: Vars.colorMetal,
	fontSize: Vars.fontSizeBody,
	fontFamily: Vars.fontFamilyBody,
	fontWeight: Vars.fontWeightBody,
	lineHeight: Vars.lineHeightBody,
	WebkitFontSmoothing: 'antialiased',
	MozOsxFontSmoothing: 'grayscale'
});

css.global('#___gatsby', {
	background: Vars.colorGrape
});

css.global('svg:not(:root)', {
	overflow: 'hidden'
});

css.global('img,svg', {
	width: '100%',
	height: 'auto',
	display: 'block',
	marginLeft: 'auto',
	marginRight: 'auto',
	userSelect: 'none'
});

css.global('a', {
	backgroundColor: 'transparent',
	textDecorationSkip: 'objects'
});

css.global('a:active,a:hover', {
	outlineWidth: 0
});

const LDJSON = [
	{
		'@context': 'http://schema.org',
		'@type': 'Person',
		name: 'Tomas Jurkevičius',
		jobTitle: 'Front End Engineer',
		url: 'https://tomas.tech',
		image: 'https://tomas.tech/icons/icon-512x512.png',
		sameAs: [
			'https://www.linkedin.com/in/tomasjurkevicius',
			'https://github.com/tomastech',
			'https://twitter.com/tomastech_'
		]
	},
	{
		'@context': 'http://schema.org/',
		'@type': 'WebSite',
		name: 'Tomas Jurkevičius, Front End Engineer | Tomas.Tech',
		alternateName: 'Tomas.Tech',
		url: 'https://tomas.tech'
	}
];

const Layout = ({ children, title, description, canonical, ogTitle, ogDescription, ogImage, location }) => {
	return (
		<HelmetProvider>
			<Helmet
				htmlAttributes={{
					lang: 'en',
					prefix: 'og: http://ogp.me/ns# fb: http://ogp.me/ns/fb#'
				}}>
				<title>{title}</title>
				<meta name="description" content={description} />

				<meta property="og:url" content={canonical} />
				<meta property="og:title" content={ogTitle ? ogTitle : title} />
				<meta property="og:description" content={ogDescription ? ogDescription : description} />
				<meta property="og:image" content={ogImage ? ogImage : '/icons/icon-512x512.png'} />
				<meta property="og:image:width" content="512" />
				<meta property="og:image:height" content="512" />
				<meta name="twitter:card" content="summary" />
				<meta name="twitter:creator" content="@tomastech_" />

				<meta name="msapplication-TileColor" content="#ffffff" />
				<link rel="mask-icon" href="/safari-pinned-tab.svg" color="#fd476c" />
				<link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
				<link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
				<link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
				<link
					rel="preload"
					href="https://fonts.googleapis.com/css?family=Roboto+Condensed:400,700%7CRoboto:400,700"
					as="style"
					onLoad="this.rel='stylesheet'"
				/>
				<link rel="canonical" href={canonical} />

				<script type="application/ld+json">{JSON.stringify(LDJSON)}</script>
			</Helmet>
			<Header />
			<Main location={location}>
				{children}
				<Footer />
			</Main>
		</HelmetProvider>
	);
};

export default Layout;
