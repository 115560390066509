import React from 'react';
import { Vars } from 'utils/vars';

const Main = ({ children }) => (
	<main
		css={{
			display: 'block',
			overflow: 'hidden',
			minHeight: '100vh',
			background: `linear-gradient(to bottom right, ${Vars.colorWhite}, ${Vars.colorMetal})`,
			backgroundAttachment: 'fixed'
		}}>
		{children}
	</main>
);

export default Main;
