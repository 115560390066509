import React from 'react';
import { Vars } from 'utils/vars';
import { GridColumnStyle } from 'utils/grid';

import Button from 'components/Button';
import Email from 'components/SVG/Email';
import Github from 'components/SVG/Github';
import Linkedin from 'components/SVG/Linkedin';
import Twitter from 'components/SVG/Twitter';

const DATA = [
	{
		id: 0,
		href: 'https://www.linkedin.com/in/tomasjurkevicius',
		text: 'LinkedIn',
		icon: <Linkedin />
	},
	{
		id: 1,
		href: 'mailto:me@tomas.tech',
		text: 'Email',
		icon: <Email />
	},
	{
		id: 2,
		href: 'https://github.com/tomastech',
		text: 'GitHub',
		icon: <Github />
	},
	{
		id: 3,
		href: 'https://twitter.com/tomastech_',
		text: 'Twitter',
		icon: <Twitter />
	}
];

const listStyles = {
	display: 'flex',
	flexFlow: 'row wrap',
	alignItems: 'center',
	textAlign: 'center',
	height: '100%',
	listStyle: 'none',
	padding: 0,
	margin: 0
};

const itemStyles = {
	height: '24px',
	...GridColumnStyle('x3')
};

const buttonStyles = {
	display: 'inline-block',
	cursor: 'pointer',
	width: '24px',
	height: '24px',
	border: 0,
	color: Vars.colorBlue,
	transform: 'scale(1)',
	transition: 'color .25s ease, transform .5s ease',
	textDecoration: 'none',
	':hover,:focus': {
		color: Vars.colorPink,
		transform: 'scale(1.2)'
	}
};

const Item = ({ href, icon, text }) => (
	<li css={itemStyles}>
		<Button to={href} css={buttonStyles} title={text} isBlank>
			{icon}
		</Button>
	</li>
);

const Social = () => <ul css={listStyles}>{DATA.map((item) => <Item key={item.id} {...item} />)}</ul>;

export default Social;
