import React from 'react';
import { Vars } from 'utils/vars';
import { Grid, GridColumnStyle } from 'utils/grid';

import HeroSceneIllustration from 'components/SVG/HeroSceneIllustration';

const heroStyles = {
	position: 'relative',
	overflow: 'hidden',
	color: Vars.colorMetal,
	background: `linear-gradient(135deg, ${Vars.colorGrape} 0%, ${Vars.colorGrapeLight} 100%)`,
	clipPath: 'polygon(0 0, 100% 0, 100% 94%, 0 100%)',
	paddingTop: Vars.gapDouble,
	paddingBottom: Vars.gap,
	'@media (min-width: 768px)': {
		paddingTop: '100px'
	}
};

const heroContainerStyles = [
	Grid,
	GridColumnStyle('x12', true),
	{
		position: 'relative',
		zIndex: 2,
		padding: `0 ${Vars.gutterHalf}`,
		alignItems: 'center',
		'@media (min-width: 768px)': {
			height: 'calc(540 / 1244 * 100vw)',
			maxHeight: '540px'
		}
	}
];

const heroCopyStyles = [
	GridColumnStyle('x12'),
	{
		'@media (min-width: 576px)': GridColumnStyle('x11'),
		'@media (min-width: 768px)': GridColumnStyle('x8'),
		'@media (min-width: 992px)': GridColumnStyle('x7'),
		'@media (min-width: 1200px)': GridColumnStyle('x6')
	}
];

const Hero = ({ children, illustration }) => (
	<section css={heroStyles}>
		<div css={heroContainerStyles}>
			<div css={heroCopyStyles}>{children}</div>
			{illustration}
		</div>
		<HeroSceneIllustration />
	</section>
);

export default Hero;
