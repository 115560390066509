import React from 'react';

const Email = (props) => (
	<svg
		viewBox="0 0 200 200"
		css={{
			width: '100%',
			height: 'auto',
			margin: 'auto',
			display: 'block'
		}}
		{...props}>
		<title>Send Tomas an email</title>
		<path
			d="M100 1a99 99 0 1 0 70 29 98.88 98.88 0 0 0-70-29zm40.85 133.91H59a8 8 0 0 1-8.51-8.28V73.5a8.08 8.08 0 0 1 2.41-6.2 8 8 0 0 1 6.24-2.24H141a7.85 7.85 0 0 1 6.14 2.24 8 8 0 0 1 2.38 6.07v53.2a8.09 8.09 0 0 1-8.65 8.35z"
			fill="currentColor"
		/>
		<path
			d="M137.46 82.18l.69-.69a3.8 3.8 0 0 0 .83-3.83 3.88 3.88 0 0 0-3-2.54 4.19 4.19 0 0 0-4 1.35l-29.24 28.35a3.37 3.37 0 0 1-2.81 1.45 3.41 3.41 0 0 1-2.81-1.45l-28.87-28a8.06 8.06 0 0 0-1-.89 4 4 0 0 0-3.8-.73 3.94 3.94 0 0 0-1.14 6.8L80 99.18l.79.82-.89.89L62.25 118a3.94 3.94 0 0 0-1.42 3.53 3.72 3.72 0 0 0 2.64 3.14 3.94 3.94 0 0 0 4.36-1.09l17.82-17.28.79-.73a232.6 232.6 0 0 0 6.53 6.27 9.6 9.6 0 0 0 7 3.07 9.49 9.49 0 0 0 7-3.07c2.14-2 4.39-4 6.86-6.3a4.62 4.62 0 0 0 .53.86q7.13 6.93 14.29 13.86c1.25 1.22 2.51 2.44 3.8 3.63a4 4 0 0 0 4.09.86 3.74 3.74 0 0 0 2.61-3.07 4 4 0 0 0-1.55-3.66L119.93 101l-.93-1a12.93 12.93 0 0 0 1.06-.83z"
			fill="currentColor"
		/>
	</svg>
);

export default Email;
