import React from 'react';

const Logo = (props) => (
	<svg viewBox="0 0 2292 600" {...props}>
		<title>Return to the homepage</title>
		<circle cx="266.6" cy="286.75" r="220" fill="#dfe5eb" />
		<path
			d="M390.1,13.3a298.11,298.11,0,0,1,36.5,143.5c0,165.7-134.3,300-300,300A299.33,299.33,0,0,1,3.1,430.3C54,523.6,152.9,586.8,266.6,586.8c165.7,0,300-134.3,300-300A300.26,300.26,0,0,0,390.1,13.3Z"
			fill="#fd476c"
		/>
		<path
			d="M266.5,58.5C140.6,58.5,38.3,160.9,38.3,286.7S140.7,515,266.5,515,494.7,412.6,494.7,286.8,392.4,58.5,266.5,58.5Zm0,432C154,490.53,62.73,399.34,62.7,286.82q0-.06,0-0.12C62.7,174.1,154,82.9,266.5,82.9s203.8,91.2,203.8,203.8S379.14,490.47,266.62,490.5H266.5Z"
			fill="#391d71"
		/>
		<path
			d="M194.6,206a8.15,8.15,0,1,0,15-6.4h0a51,51,0,0,0-97.9,19.9,51.87,51.87,0,0,0,4,19.8,8.13,8.13,0,0,0,7.5,5,12,12,0,0,0,3.2-.6,8,8,0,0,0,4.42-10.41L130.7,233a34.26,34.26,0,0,1-2.7-13.5A34.66,34.66,0,0,1,194.6,206h0Z"
			fill="#391d71"
		/>
		<path
			d="M317.5,126.3a51,51,0,0,0-50.9,50.9,52.75,52.75,0,0,0,4,19.9,8.13,8.13,0,0,0,7.5,5,12,12,0,0,0,3.2-.6A8.07,8.07,0,0,0,285.67,191l-0.07-.16a34.67,34.67,0,0,1,63.9-26.9,8.15,8.15,0,0,0,15-6.4,50.91,50.91,0,0,0-47-31.2h0Z"
			fill="#391d71"
		/>
		<path
			d="M334.9,246a8.23,8.23,0,0,0-10.7,4.2c-10,23.5-31,42.2-55.9,50-18.6,5.8-47.8,8.3-81.7-12.9a8.13,8.13,0,0,0-8.6,13.8c20.5,12.8,42.5,19.4,64.1,19.4a105.61,105.61,0,0,0,31.1-4.7c29.4-9.2,54.1-31.3,66-59.1a8,8,0,0,0-4-10.58L334.9,246h0Z"
			fill="#391d71"
		/>
		<path d="M804,231.5H759.2v168H720.4v-168H675.8v-31H804v31Z" fill="currentColor" />
		<path
			d="M976.7,328c0,23.3-6.3,41.6-19,54.7s-29.5,19.7-50.6,19.7-37.9-6.6-50.4-19.7-18.9-31.4-18.9-54.7v-55.8q0-34.8,18.9-54.8c12.6-13.3,29.3-19.9,50.3-19.9s37.8,6.6,50.6,19.9,19.1,31.5,19.1,54.8V328h0ZM938,271.85c0-14.3-2.6-25-7.9-32.3s-13-10.9-23.1-10.9-17.9,3.6-23,10.8-7.7,18-7.7,32.4V328c0,14.4,2.6,25.3,7.8,32.5s12.9,10.8,23,10.8,17.7-3.6,23-10.8,7.9-18.1,7.9-32.5v-56.2Z"
			fill="currentColor"
		/>
		<path
			d="M1071.9,200.4l35.7,146.2h0.8l36.1-146.2h50.7V399.5h-38.7V341.7l3.3-77.4-0.8-.1-38,135.4h-25.8l-37.6-134.3-0.8.1,3.4,76.3v57.8h-38.7V200.4h50.4Z"
			fill="currentColor"
		/>
		<path
			d="M1336.6,357.05h-52.5l-10.7,42.5h-39.2l56.1-199.1h40.5l55.9,199.1h-39.2Zm-44.5-31h36.6l-17.9-70.4H1310Z"
			fill="currentColor"
		/>
		<path
			d="M1509.8,347.55c0-7.7-2-13.9-6-18.4s-11.1-9-21.2-13.3q-31-10.8-46.6-24.5c-10.3-9.2-15.5-22-15.5-38.5q0-24.45,18-39.9t46.1-15.4q28.5,0,46.2,17.2c11.8,11.4,17.6,25.9,17.2,43.3l-0.3.8H1510c0-9.1-2.3-16.5-6.8-22.1s-11-8.5-19.3-8.5c-7.7,0-13.8,2.3-18.1,7s-6.5,10.6-6.5,17.7a20.54,20.54,0,0,0,7.2,16.2c4.8,4.2,13.2,9,25.2,14.2q28.35,9.6,42.6,24.3c9.5,9.8,14.3,23,14.3,39.7,0,17.2-5.7,30.7-17.2,40.5s-26.8,14.6-45.8,14.6-35.4-5.5-49.3-16.6-20.6-27-20.2-47.6l0.3-.8h37.9c0,12.3,2.6,21.1,7.8,26.5s13,8.1,23.5,8.1q12.3,0,18.3-6.6C1507.9,361.05,1509.8,355.05,1509.8,347.55Z"
			fill="currentColor"
		/>
		<path d="M1630.3,398.65h-38.4v-35.3h38.4v35.3Z" fill="currentColor" />
		<path d="M1780.7,231.5h-44.8v168h-38.8v-168h-44.6v-31h128.2v31h0Z" fill="currentColor" />
		<path d="M1926.7,312.2h-66.9v56.3h78.6v31H1820.8v-199h117.3v31h-78.3v49.6h66.9v31.1h0Z" fill="currentColor" />
		<path
			d="M2103.7,335.45l0.4,0.8q0.3,32.85-15.6,49.5t-46.8,16.7q-30.9,0-49.4-18.9t-18.5-53.7v-59.5c0-23.1,5.9-40.9,17.8-53.7s27.8-19.1,47.8-19.1q31.65,0,47.9,17t16.7,50.2l-0.4.8h-37.5c0.5-13.1-1.2-22.5-5.3-28.3s-11.3-8.6-21.5-8.6c-8.8,0-15.4,3.4-20.1,10.3s-7,17.2-7,31.1v59.8c0,14.1,2.5,24.5,7.5,31.3s12.2,10.3,21.8,10.3c9.4,0,15.9-2.7,19.6-8.1s5.3-14.7,4.9-27.8l37.7-.1h0Z"
			fill="currentColor"
		/>
		<path d="M2286,399.5h-38.8V317.1h-61.8v82.4h-39v-199h39v85.6h61.8V200.5H2286v199Z" fill="currentColor" />
	</svg>
);

export default Logo;
