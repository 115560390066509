import { Vars } from 'utils/vars';

export let Grid = {
	display: 'flex',
	flexFlow: 'row wrap',
	marginRight: 'auto',
	marginLeft: 'auto',
	width: '100%',
	'@media (min-width: 576px)': {
		maxWidth: '576px'
	},
	'@media (min-width: 768px)': {
		maxWidth: '768px'
	},
	'@media (min-width: 992px)': {
		maxWidth: '992px'
	},
	'@media (min-width: 1200px)': {
		maxWidth: '1180px'
	}
};

export let GridColumns = {
	x1: `${(100 / 12) * 1}%`,
	x2: `${(100 / 12) * 2}%`,
	x3: `${(100 / 12) * 3}%`,
	x4: `${(100 / 12) * 4}%`,
	x5: `${(100 / 12) * 5}%`,
	x6: `${(100 / 12) * 6}%`,
	x7: `${(100 / 12) * 7}%`,
	x8: `${(100 / 12) * 8}%`,
	x9: `${(100 / 12) * 9}%`,
	x10: `${(100 / 12) * 10}%`,
	x11: `${(100 / 12) * 11}%`,
	x12: `${(100 / 12) * 12}%`
};

export let GridColumnStyle = (column, noPadding) => {
	let styles = {
		flex: `0 1 ${GridColumns[column]}`,
		maxWidth: GridColumns[column]
	};

	if (!noPadding) {
		styles = {
			...styles,
			paddingLeft: Vars.gutterHalf,
			paddingRight: Vars.gutterHalf
		};
	}

	return styles;
};
